.listening-animation {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .bar {
    animation-name: wave-lg;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    background: var(--party-color-background, red); // Change the color for the bars
    margin: 0 1.5px;
    height: 0.625rem;
    width: 0.625rem; // Change the number for the bar width
    border-radius: 3rem;

    &:nth-child(-n + 7),
    &:nth-last-child(-n + 7) {
      animation-name: wave-md;
    }

    &:nth-child(-n + 3),
    &:nth-last-child(-n + 3) {
      animation-name: wave-sm;
    }
  }
}

@keyframes wave-sm {
  0% {
    opacity: 0.35;
    height: 0.625rem;
  }
  100% {
    opacity: 1;
    height: 1.5625rem;
  }
}

@keyframes wave-md {
  0% {
    opacity: 0.35;
    height: 1rem;
  }
  100% {
    opacity: 1;
    height: 4rem;
  }
}

@keyframes wave-lg {
  0% {
    opacity: 0.35;
    height: 1rem;
  }
  100% {
    opacity: 1;
    height: 5rem;
  }
}
