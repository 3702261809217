@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'ProjectX';
    font-style: normal;
    font-weight: 300;
    src: url(../public/fonts/ProjectXv.08-Light.woff2) format('woff2');
  }

  @font-face {
    font-family: 'ProjectX';
    font-style: normal;
    font-weight: 400;
    src: url(../public/fonts/ProjectXv.08-Regular.woff2) format('woff2');
  }

  @font-face {
    font-family: 'ProjectX';
    font-style: normal;
    font-weight: 500;
    src: url(../public/fonts/ProjectXv.08-Medium.woff2) format('woff2');
  }

  @font-face {
    font-family: 'ProjectX';
    font-style: normal;
    font-weight: 700;
    src: url(../public/fonts/ProjectXv.08-Bold.woff2) format('woff2');
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-eidraCream font-sans;
}

html.kiosk-mode {
  font-size: 200%;
}

.simple-listening-animation {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

