.swiper {
  --swiper-pagination-bullet-size: .25rem;
  --swiper-pagination-color: #000;
  --swiper-pagination-bottom: 2rem;
  --swiper-theme-color: rgba(0, 0, 0, 0.5)
}

.swiper .swiper-slide:hover {
  cursor: grab;
}

.swiper .swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  height: .5rem;
}

.swiper .swiper-pagination-bullet {
  transition:
    width 0.35s,
    height 0.35s,
    background-color 0.35s;
}

.swiper .swiper-pagination-bullet-active {
  --swiper-pagination-bullet-size: .5rem;
}

.swiper .autoplay-progress {
  position: absolute;
  left: 50%;
  bottom: .25rem;
  z-index: 10;
  width: 1.5rem;
  height: 1.5rem;
  transform: translateX(-50%);
}

.swiper .autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: .25rem;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(126px * (1 - var(--progress)));
  stroke-dasharray: 126px;
  transform: rotate(-90deg);
}